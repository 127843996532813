
  import { computed, defineComponent, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { useI18n } from 'vue-i18n/index';
  import store from '@/store/';
  import Swal from 'sweetalert2/dist/sweetalert2.min.js';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'sign-in',
    components: {
      Field,
      Form,
      ErrorMessage,
    },
    beforeRouteEnter(to, from, next) {
      if (store.getters.isUserAuthenticated) {
        next({ name: 'admins-listing' });
      } else {
        next();
      }
    },
    setup() {
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { t, te, locale } = useI18n();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const pathRoute = ref<string>(route.path);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const changeLang = () => {
        // store.dispatch(Actions.CHANGE_LOCALE, 'ar');
        const switchTo = locale.value === 'ar' ? 'en' : 'ar';
        locale.value = switchTo;
        // eslint-disable-next-line
        // @ts-ignore
        document.getElementById('appStyle')?.href =
          switchTo === 'ar' ? '/css/style.rtl.css' : '/css/style.css';
        localStorage.setItem('lang', switchTo);
        if (switchTo === 'ar') {
          document.documentElement.setAttribute('lang', 'ar');
          document.documentElement.setAttribute('dir', 'rtl');
          document.documentElement.setAttribute('direction', 'rtl');
          document.documentElement.setAttribute('style', 'direction: rtl;');
        } else {
          document.documentElement.setAttribute('lang', 'en');
          document.documentElement.removeAttribute('dir');
          document.documentElement.removeAttribute('direction');
          document.documentElement.removeAttribute('style');
        }
      };

      //Create form validation object
      const login = Yup.object().shape({
        email: Yup.string()
          .email(() => translate('EMAIL_NOT_VALID'))
          .required(() => translate('EMAIL_IS_REQUIRED_FIELD'))
          .label('Email'),
        password: Yup.string()
          .required(() => translate('PASSWORD_IS_REQUIRED_FIELD'))
          .label('Password'),
      });

      //Form submit function
      const onSubmitLogin = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          // Activate indicator
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        if (pathRoute.value === '/sign-in-appraiser') {
          values.userType = 'appraiser';
        } else if (pathRoute.value === '/sign-in-banks') {
          values.userType = 'banks';
        }

        // Send login request
        await store.dispatch(Actions.LOGIN, values);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (!error) {
          if (values.userType == 'appraiser') {
            return router.push({ name: 'appraiser-appraisals-listing' });
          } else return router.push({ name: 'admins-listing' });
        }

        Swal.fire({
          text: translate(error[0]),
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: translate('tryAgainExcl'),
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger',
          },
        });
        //Deactivate indicator
        submitButton.value?.removeAttribute('data-kt-indicator');
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      };
      const signInTitle = computed(() => {
        if (pathRoute.value === '/sign-in-appraiser') {
          return translate('signInAppraiser');
        } else if (pathRoute.value === '/sign-in-banks') {
          return translate('signInBanks');
        }
        return translate('signInTitle');
      });

      return {
        onSubmitLogin,
        login,
        submitButton,
        changeLang,
        translate,
        signInTitle,
      };
    },
  });
